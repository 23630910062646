


$(document).on('click', '.nav-link', function(e){
    e.preventDefault();
    let target = $(this).attr('href');
    $('.nav-item, .tab-pane').removeClass('active');
    $(this).parent().addClass('active');
    $(target).addClass('active');
})


$(document).ready(function(){
    $('.mob-menu-btn').click(function(e){
        e.preventDefault();
        $(this).toggleClass('opened');
        $('.top-menu-block').toggleClass('opened');
    });
    $('.top-menu-block a').click(function(e){
        //e.preventDefault();
        $('.mob-menu-btn').toggleClass('opened');
        $('.top-menu-block').toggleClass('opened');
    });
    if($(window).width() > 1024) {
        let lastScrollTop = 0;
        // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
        window.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
            let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
            if (st > lastScrollTop || st == 0) {
                $('body').removeClass('upscroll');
                // downscroll code
            } else if (st < lastScrollTop) {
                $('body').addClass('upscroll');
                // upscroll code
            } // else was horizontal scroll
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }, false);
    }
});